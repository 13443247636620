/*******************
 Breadcrumb and page title
*******************/

.page-breadcrumb {
    padding: 25px 30px 0;
    .page-title{
        margin-bottom: 0
    }
    .breadcrumb {
        padding: 0;
        margin: 0;
        background: transparent;
        font-size: 16px;
        .breadcrumb-item+.breadcrumb-item::before {
            content: "\e649";
            font-family: "themify", Arial, sans-serif;

            color: $gray-400;
            font-size: 11px;
        }
    }
}
