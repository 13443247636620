// ########################################
// Checkbox
// ########################################

.checkbox {
    padding-left: 20px;
    label {
        display: block;
        padding-left: 5px;
        position: relative;
        &::before {
            -o-transition: 0.3s ease-in-out;
            -webkit-transition: 0.3s ease-in-out;
            background-color: $white;
            border-radius: 1px;
            border: 1px solid $border-color;
            content: "";
            display: inline-block;
            height: 17px;
            left: 0;
            top:3px;
            margin-left: -20px;
            position: absolute;
            transition: 0.3s ease-in-out;
            width: 17px;
            outline: none;
        }
        &::after {
            color: $dark;
            display: inline-block;
            font-size: 11px;
            height: 16px;
            left: 0;
            margin-left: -20px;
            padding-left: 3px;
            padding-top: 1px;
            position: absolute;
            top: 3px;
            width: 16px;
        }
    }
    input[type="checkbox"] {
        cursor: pointer;
        opacity: 0;
        z-index: 1;
        position: absolute;
        left: 0px;
        outline: none;
        &:disabled + label {
            opacity: 0.65;
        }
    }
    input[type="checkbox"]:focus + label {
        &::before {
            outline-offset: -2px;
            outline: none;
            
        }
    }
    input[type="checkbox"]:checked + label {
        &::after {
            content: "\f00c";
            font-family: 'Font Awesome 5 Free', sans-serif;
            font-weight: 900;
        }
    }
    input[type="checkbox"]:disabled + label {
        &::before {
            background-color: $light;
            cursor: not-allowed;
        }
    }
}

.checkbox.checkbox-circle {
    label {
        &::before {
            border-radius: 50%;
        }
    }
}

.checkbox.checkbox-inline {
    margin-top: 0;
}

.checkbox.checkbox-single {
    label {
        height: 17px;
    }
}

.checkbox-primary {
    input[type="checkbox"]:checked + label {
        &::before {
            background-color: $primary;
            border-color: $primary;
        }
        &::after {
            color: $white;
        }
    }
}

.checkbox-danger {
    input[type="checkbox"]:checked + label {
        &::before {
            background-color: $danger;
            border-color: $danger;
        }
        &::after {
            color: $white;
        }
    }
}

.checkbox-info {
    input[type="checkbox"]:checked + label {
        &::before {
            background-color: $info;
            border-color: $info;
        }
        &::after {
            color: $white;
        }
    }
}

.checkbox-warning {
    input[type="checkbox"]:checked + label {
        &::before {
            background-color: $warning;
            border-color: $warning;
        }
        &::after {
            color: $white;
        }
    }
}

.checkbox-success {
    input[type="checkbox"]:checked + label {
        &::before {
            background-color: $success;
            border-color: $success;
        }
        &::after {
            color: $white;
        }
    }
}

.checkbox-purple {
    input[type="checkbox"]:checked + label {
        &::before {
            background-color: $purple;
            border-color: $purple;
        }
        &::after {
            color: $white;
        }
    }
}

.checkbox-red {
    input[type="checkbox"]:checked + label {
        &::before {
            background-color: $danger;
            border-color: $danger;
        }
        &::after {
            color: $white;
        }
    }
}

.checkbox-inverse {
    input[type="checkbox"]:checked + label {
        &::before {
            background-color: $inverse;
            border-color: $inverse;
        }
        &::after {
            color: $white;
        }
    }
}

// ########################################
// Radios 
// ########################################

.radio {
    padding-left: 20px;
    label {
        display: inline-block;
        padding-left: 5px;
        position: relative;
        &::before {
            -o-transition: border 0.5s ease-in-out;
            -webkit-transition: border 0.5s ease-in-out;
            background-color: $white;
            border-radius: 50%;
            border: 1px solid $border-color;
            content: "";
            display: inline-block;
            height: 17px;
            left: 0;
            margin-left: -20px;
            position: absolute;
            transition: border 0.5s ease-in-out;
            width: 17px;
            outline: none;
        }
        &::after {
            -moz-transition: -moz-transform 0.3s cubic-bezier(0.8, -0.33, 0.2, 1.33);
            -ms-transform: scale(0, 0);
            -o-transform: scale(0, 0);
            -o-transition: -o-transform 0.3s cubic-bezier(0.8, -0.33, 0.2, 1.33);
            -webkit-transform: scale(0, 0);
            -webkit-transition: -webkit-transform 0.3s cubic-bezier(0.8, -0.33, 0.2, 1.33);
            background-color: $dark;
            border-radius: 50%;
            content: " ";
            display: inline-block;
            height: 7px;
            left: 5px;
            margin-left: -20px;
            position: absolute;
            top: 5px;
            transform: scale(0, 0);
            transition: transform 0.3s cubic-bezier(0.8, -0.33, 0.2, 1.33);
            width: 7px;
        }
    }
    input[type="radio"] {
        cursor: pointer;
        opacity: 0;
        z-index: 1;
        outline: none;
        &:disabled + label {
            opacity: 0.65;
        }
    }
    input[type="radio"]:focus + label {
        &::before {
            outline-offset: -2px;
            outline: none;
            
        }
    }
    input[type="radio"]:checked + label {
        &::after {
            -ms-transform: scale(1, 1);
            -o-transform: scale(1, 1);
            -webkit-transform: scale(1, 1);
            transform: scale(1, 1);
        }
    }
    input[type="radio"]:disabled + label {
        &::before {
            cursor: not-allowed;
        }
    }
}

.radio.radio-inline {
    margin-top: 0;
}

.radio.radio-single {
    label {
        height: 17px;
    }
}

.radio-primary {
    input[type="radio"] + label {
        &::after {
            background-color: $primary;
        }
    }
    input[type="radio"]:checked + label {
        &::before {
            border-color: $primary;
        }
        &::after {
            background-color: $primary;
        }
    }
}

.radio-danger {
    input[type="radio"] + label {
        &::after {
            background-color: $danger;
        }
    }
    input[type="radio"]:checked + label {
        &::before {
            border-color: $danger;
        }
        &::after {
            background-color: $danger;
        }
    }
}

.radio-info {
    input[type="radio"] + label {
        &::after {
            background-color: $info;
        }
    }
    input[type="radio"]:checked + label {
        &::before {
            border-color: $info;
        }
        &::after {
            background-color: $info;
        }
    }
}

.radio-warning {
    input[type="radio"] + label {
        &::after {
            background-color: $warning;
        }
    }
    input[type="radio"]:checked + label {
        &::before {
            border-color: $warning;
        }
        &::after {
            background-color: $warning;
        }
    }
}

.radio-success {
    input[type="radio"] + label {
        &::after {
            background-color: $success;
        }
    }
    input[type="radio"]:checked + label {
        &::before {
            border-color: $success;
        }
        &::after {
            background-color: $success;
        }
    }
}

.radio-purple {
    input[type="radio"] + label {
        &::after {
            background-color: $purple;
        }
    }
    input[type="radio"]:checked + label {
        &::before {
            border-color: $purple;
        }
        &::after {
            background-color: $purple;
        }
    }
}

.radio-red {
    input[type="radio"] + label {
        &::after {
            background-color: $danger;
        }
    }
    input[type="radio"]:checked + label {
        &::before {
            border-color: $danger;
        }
        &::after {
            background-color: $danger;
        }
    }
}

.checkbox label, .radio label {
    cursor:pointer;
}